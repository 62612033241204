import router from '@/router';
import { GET_REQUEST, POST_REQUEST } from '@/services/http';
import { makeQueryStringByObject } from '@lemontree-ai/lemontree-admin-common-front/utils/urlUtils';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import apiPath from '@/services/apiPath';

export default class StoreListViewModel {
  constructor() {
    this.searchDataList = [
      {
        title:'계약상태',
        type: 'Checkbox',
        dataList:'store_status',
        grid: 100,
        isFull:true,
        value:['REGISTERED','UNREGISTERED'],
      },
      {
        title:'검색어',
        type: 'SelectboxInput',
        placeholder2:'ID, 상호, 대표자, 등록번호, 이메일',
        dataList:[
          { id:'id', text:'ID' },
          { id:'store_name', text:'상호' },
          { id:'ceo_name', text:'대표자' },
          { id:'register_number', text:'등록번호' },
          { id:'email', text:'이메일' },
        ],
        grid: 100,
        isFull:true,
        value1:'id',
        value2:'',
      },
    ]
    this.boardData = {
      title:'가맹점 검색결과',
      drawDataList: [
        {
          title:'등록일',
          width:'100px',
          value:'createdAt',
          filter:{
            name:'convertDateFormat',
            value:'YYYY-MM-DD'
          },
        },
        {
          title:'상태',
          width:'60px',
          value:'status',
          filter:{
            name:'convertIdToText',
            value:'store_status'
          },
          class: { classColorFilter:{ name:'convertIdToColor', dataListName:'store_status', prev:'tc' } },
          isAlignLeft: true,
        },
        {
          title:'가맹코드',
          width:'200px',
          value:'appId',
          isAlignLeft: true,
        },
        // {
        //   title:'회사구분',
        //   width:'72px',
        //   value:'',
        //   filter:{
        //     name:'convertIdToText',
        //     value:'store_company_type'
        //   },
        //   isAlignLeft: true,
        //   emptyValueText:'-',
        // },
        {
          title:'법인명/상호',
          width:'',
          value:'name',
          isAlignLeft: true,
          isEllip: true,
        },
        {
          title:'대표명',
          width:'100px',
          value:'presidentName',
          isAlignLeft: true,
        },
        // {
        //   title:'사업자 등록번호',
        //   width:'120px',
        //   value:'',
        //   isAlignLeft: true,
        //   emptyValueText:'-',
        // },
        {
          title:'대표이메일',
          width:'200px',
          value:'email',
          isAlignLeft: true,
          isEllip: true,
        },
        // {
        //   title:'신청서',
        //   width:'64px',
        //   value:'',
        //   isAlignLeft: true,
        //   emptyValueText:'-',
        // },
      ],
      // markData : [
      //   {
      //     'id' : this.searchDataList[0].value1,
      //     'value' : this.searchDataList[0].value2
      //   }
      // ],
      option:{
        isTotal: true,
        isSize: false,
        // rowIdValue: 'appId'
      }
    };
    this.dataList = [];
    this.searchParams = {
      // page:0,
      // pageSize:10,
      // direction: 'DESC'
    };
    this.paginationData = {
      totalCount:0,
      totalPage:0
    };
  }
  init(){
    this.getStoreList();
  }
  onSearch(){
    this.getStoreList();
  }
  onClickRow(rowId){
    router.push({ name: 'STORE_STORE_MODIFY', params: { id : rowId } });
  }
  getStoreList(){
    const query = makeQueryStringByObject(this.searchParams);
    const path = `${apiPath.SOTRE_LIST}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.dataList = resultData;
      this.paginationData.totalCount = resultData.length;
      this.paginationData.totalPage = 1;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}